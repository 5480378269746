import { createSlice } from "@reduxjs/toolkit";
import { ITrainingPlanSlice } from "../../interfaces/training-plan/redux/ITrainingPlanSlice";
import { Listing } from "../../interfaces/training-plan/Listing";
import { IUserData } from "../../interfaces/user/IUserData";
import { ITrainingPlanReview } from "../../interfaces/training-plan/ITrainingPlanReview";
import { ListingsFromApi } from "../../interfaces/training-plan/ListingsFromApi";
import { ISwapsAPI } from "../../interfaces/training-plan/ISwapsAPI";
import { ListingType } from "../../enums/listing/TitleTypes";
import { ILibraryListing } from "../../interfaces/training-plan/ILibraryListing";
import { PriceFromStripe } from "../../interfaces/apiRequests/PriceFromStripe";

const initialState = {
  isFollowingCoach: undefined,
  isLoadingFollowBtn: true,
  listingData: null,
  coachData: null,
  similarListings: null,
  reviews: null,
  loading: true,
  loadingLoginListingModal: false,
  errorLoginListingModal: null,
  isLoadingPurchaseOptions: false,
  isLoadingListingModal: true,
  gotSubscription: false,
  swaps: null,
  isOpenShareModal: false,
  isSavedListing: undefined,
  listingType: ListingType.TRAINING_PLAN,
  isOpenSuccessScheduleModal: false,
  isOpenLimitModalSwap: false,
  listingToReturn: null,
  borrowedListings: [],
  ownedListings: [],
  userFullSubscriptionData: null,
} as ITrainingPlanSlice;

export const trainingPlanSlice = createSlice({
  name: "trainingPlan",
  initialState,
  reducers: {
    setCurrentListing: (state, action: { payload: Listing }) => {
      state.listingData = action.payload;
      state.loading = false;
    },
    setCurrentCoachListing: (state, action: { payload: IUserData }) => {
      state.coachData = action.payload;
    },
    setCurrentReviews: (state, action: { payload: ITrainingPlanReview[] }) => {
      state.reviews = action.payload;
    },
    setCurrentSimilarListings: (
      state,
      action: { payload: ListingsFromApi }
    ) => {
      state.similarListings = action.payload;
    },
    invertFollowingCoach: (state) => {
      state.isFollowingCoach = !state.isFollowingCoach;
    },
    invertLoadingFollowBtn: (state) => {
      state.isLoadingFollowBtn = !state.isLoadingFollowBtn;
    },
    setIsFollowingCoach: (state, action: { payload: boolean }) => {
      state.isFollowingCoach = action.payload;
    },
    setIsLoadingFollowBtn: (state, action: { payload: boolean }) => {
      state.isLoadingFollowBtn = action.payload;
    },
    setIsLoadingLoginListingModal: (state, action: { payload: boolean }) => {
      state.loadingLoginListingModal = action.payload;
    },
    setErrorLoginListingModal: (
      state,
      action: { payload: IUserData["errors"] }
    ) => {
      state.errorLoginListingModal = action.payload;
    },
    setIsLoadingListingModal: (state, action: { payload: boolean }) => {
      state.isLoadingListingModal = action.payload;
    },
    setGotSubscription: (state, action: { payload: boolean }) => {
      state.gotSubscription = action.payload;
    },
    setCurrentSwaps: (state, action: { payload: ISwapsAPI }) => {
      state.swaps = action.payload;
    },
    setIsOpenShareModal: (state, action: { payload: boolean }) => {
      state.isOpenShareModal = action.payload;
    },
    setIsSavedListing: (state, action: { payload: boolean }) => {
      state.isSavedListing = action.payload;
    },
    setListingType: (state, action: { payload: ListingType }) => {
      state.listingType = action.payload;
    },
    setIsOpenSuccessScheduleModal: (state, action: { payload: boolean }) => {
      state.isOpenSuccessScheduleModal = action.payload;
    },
    setIsOpenLimitModalSwap: (state, action: { payload: boolean }) => {
      state.isOpenLimitModalSwap = action.payload;
      state.listingToReturn = null;
    },
    setListingToReturn: (state, action: { payload: Listing }) => {
      if (state.listingToReturn?.id === action.payload.id) {
        state.listingToReturn = null;
        return;
      }
      state.listingToReturn = action.payload;
    },
    setBorrowedListings: (state, action: { payload: ILibraryListing[] }) => {
      state.borrowedListings = action.payload;
    },
    setUserFullSubscriptionData: (
      state,
      action: { payload: PriceFromStripe }
    ) => {
      state.userFullSubscriptionData = action.payload;
    },
    setOwnedListings: (state, action: { payload: Listing[] }) => {
      state.ownedListings = action.payload;
    },
  },
});

export const {
  invertFollowingCoach,
  invertLoadingFollowBtn,
  setIsFollowingCoach,
  setIsLoadingFollowBtn,
  setCurrentListing,
  setCurrentCoachListing,
  setCurrentReviews,
  setCurrentSimilarListings,
  setIsLoadingLoginListingModal,
  setErrorLoginListingModal,
  setIsLoadingListingModal,
  setGotSubscription,
  setCurrentSwaps,
  setIsOpenShareModal,
  setIsSavedListing,
  setListingType,
  setIsOpenSuccessScheduleModal,
  setIsOpenLimitModalSwap,
  setListingToReturn,
  setBorrowedListings,
  setUserFullSubscriptionData,
  setOwnedListings,
} = trainingPlanSlice.actions;

export default trainingPlanSlice.reducer;
