export const primaryColors = {
  "50": "#000A3E",
  "100": "#001258",
  "200": "#00228B",
  "300": "#0032C2",
  "400": "#00218C",
  "500": "#4A69F8",
  "600": "#6F88FF",
  "700": "#95A6FF",
  "800": "#B9C3FF",
  "900": "#DEE1FF",
};
export const secondaryColors = {
  "50": "#0C1021",
  "100": "#171A2C",
  "200": "#2C2F42",
  "300": "#434659",
  "400": "#5A5D72",
  "500": "#73768B",
  "600": "#8D8FA6",
  "700": "#A7AAC1",
  "800": "#C3C5DD",
  "900": "#DFE1F9",
  "1000": "#FFFFFF",
};

export const tertiaryColors = {
  "0": "#000000",
  "50": "#20071D",
  "100": "#2D1228",
  "200": "#44263E",
  "250": "#503149",
  "300": "#5D3C55",
  "350": "#694861",
  "400": "#76546E",
  "500": "#916C87",
  "600": "#AC85A1",
  "700": "#C89FBC",
  "800": "#E5BAD8",
  "900": "#FFD7F2",
  "950": "#FFEBF6",
  "980": "#FFF7F9",
  "990": "#FFFBFF",
  "1000": "#FFFFFF",
};

export const errorColors = {
  "50": "#2D0001",
  "100": "#410002",
  "200": "#690005",
  "300": "#93000A",
  "400": "#BA1A1A",
  "500": "#DE37300",
  "600": "#FF5449",
  "700": "#FF897D",
  "800": "#FFB4AB",
  "900": "#FFDAD6",
};
export const neutralColors = {
  "0": "#000000",
  "50": "#101114",
  "100": "#1B1B1F",
  "200": "#303034",
  "250": "#3B3B3F",
  "300": "#47464A",
  "350": "#535256",
  "400": "#5F5E62",
  "500": "#78767A",
  "600": "#919094",
  "700": "#ACAAAF",
  "800": "#C8C6CA",
  "900": "#E4E1E6",
  "950": "#F3F0F4",
  "980": "#FBF8FD",
  "990": "#FEFBFF",
  "1000": "#FFFFFF",
};

export const neutralVariantColors = {
  "0": "#000000",
  "50": "#0F1018",
  "100": "#1A1B23",
  "200": "#2F3038",
  "250": "#3A3B43",
  "300": "#46464F",
  "350": "#51525B",
  "400": "#5D5E67",
  "500": "#767680",
  "600": "#90909A",
  "700": "#ABAAB4",
  "800": "#C6C5D0",
  "900": "#E3E1EC",
  "950": "#F1EFFA",
  "980": "#FBF8FF",
  "990": "#FEFBFF",
  "1000": "#FFFFFF",
};

export const detachableColors = {
  "0": "#000",
  "100": "#FFF",
};
