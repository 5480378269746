import "../styles/globals.css";
import "../styles/Details.css";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import * as ga from "../lib/ga";
import store from "../redux/store";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";
import Script from "next/script";
import { StyledEngineProvider } from "@mui/material";

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const [uri, setUri] = useState("");
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    setUri(window.location.href);
  }, []);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-54R92F4" });
  }, []);

  return (
    <>
      <Head>
        <title>Online Sports Coaching | Sports Performance Hub | POD1UM</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        <link rel="dns-prefetch" href="//pod1um.com" />
        <link rel="dns-prefetch" href="//www.googletagmanager.com" />
        <link rel="dns-prefetch" href="//cdn.firstpromoter.com" />
        <link rel="dns-prefetch" href="//cloudfront.net" />
        <link rel="dns-prefetch" href="//fonts.gstatic.com" />
        <link rel="dns-prefetch" href="//testapi.pod1um.com" />
        <link rel="dns-prefetch" href="//region1.google-analytics.com" />
        <link rel="dns-prefetch" href="//region1.analytics.google.com" />
        <link rel="dns-prefetch" href="//www.google-analytics.com" />
        <link rel="dns-prefetch" href="//stats.g.doubleclick.net" />
        <link rel="dns-prefetch" href="//www.google.ie" />
        <link rel="dns-prefetch" href="//fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:image" content="/favicon.ico" />

        {process.env.NEXT_PUBLIC_APP_ENV !== "test" && (
          <link rel="canonical" href={uri} />
        )}

        {process.env.NEXT_PUBLIC_APP_ENV === "test" && (
          <link rel="canonical" href="https://pod1um.com/" />
        )}

        {process.env.NEXT_PUBLIC_APP_ENV === "test" && (
          <>
            <meta name="robots" content="none" />
          </>
        )}
        {process.env.NEXT_PUBLIC_APP_ENV !== "test" && (
          <>
            <meta
              content="all, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
              name="robots"
            />
            <meta name="robots" content="index, follow" />
            <meta
              content="all, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
              name="googlebot"
            />
            <meta
              content="all, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
              name="bingbot"
            />
          </>
        )}
        <meta
          name="description"
          content="Engage the largest network of trusted sports professionals to unlock your full potential"
        />
        <meta
          name="dc.description"
          content="Engage the largest network of trusted sports professionals to unlock your full potential"
        />
        <meta
          name="og:description"
          content="Engage the largest network of trusted sports professionals to unlock your full potential"
        />
        <meta
          name="twitter:description"
          content="Engage the largest network of 
           sports professionals to unlock your full potential"
        />

        <meta name="dc.relation" content="https://pod1um.com/" />
        <meta name="dc.source" content="https://pod1um.com/" />
        <meta name="dc.language" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en" />
        <meta property="og:url" content={uri} />
        <meta property="og:site_name" content="Pod1um" />
        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Pod1um" />
        <meta property="og:url" content={uri} />
        <meta name="twitter:card" content="summary" />
        <meta httpEquiv="x-dns-prefetch-control" content="on" />

        <script
          id="JSON-LD"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "POD1UM",
              title: "Online Sports Coaching | Sports Performance Hub | POD1UM",
              url: "https://pod1um.com",
              description:
                "POD1UM | Sports Performance | Strength & Conditioning Coaches, Plans & Workouts | Experience Elite",
              logo: "https://pod1um.com/favicon.ico",
              sameAs: [
                "https://twitter.com/POD1UM_App",
                "https://www.facebook.com/pod1um",
                "https://www.linkedin.com/company/80706902/",
              ],
            }),
          }}
        />
      </Head>

      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script
        id="Google-Analytics"
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
        });
      `,
        }}
      />

      <Script
        async
        id="First-promoter-tracking"
        dangerouslySetInnerHTML={{
          __html: `
            _fprom=window._fprom||[];window._fprom=_fprom;_fprom.push(["url_tracking",true]); if (window.$FPROM){ $FPROM.trackVisitor({url_tracking: true})} else if(window.fpr) fpr("urlTracking");
          `,
        }}
      />

      <Script
        async
        id="First-promoter"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
            fpr("init", {cid:"k6xxa90y"}); 
            fpr("click");
          `,
        }}
      />

      {process.env.NEXT_PUBLIC_APP_ENV !== "test" ? (
        <Script
          id="ms-clarity-prod"
          async
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hmrpx5ko1z");`,
          }}
        />
      ) : (
        <Script
          id="ms-clarity-dev"
          async
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "hk64aiha01");`,
          }}
        />
      )}

      <Script src="https://cdn.firstpromoter.com/fpr.js" async />

      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <Component {...pageProps} />
        </StyledEngineProvider>
      </Provider>
    </>
  );
};

export default appWithTranslation(App);
