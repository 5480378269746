import { createSlice } from "@reduxjs/toolkit";
import { IThemeSlice } from "../interfaces/globalComponents/filters/redux/IThemeSlice";
import defaultTheme, {
  darkComponentColors,
} from "../styles/material/defaultMaterialTheme";
import { lightComponentColors } from "../styles/material/lightMaterialTheme";
import lightTheme from "../styles/material/lightMaterialTheme";

const initialState = {
  materialTheme: defaultTheme,
  componentColors: darkComponentColors,
  mode: "dark",
} as IThemeSlice;

export const themeSlice = createSlice({
  name: "materialTheme",
  initialState,
  reducers: {
    changeMode: (state) => {
      if (state.mode == "dark") {
        state.materialTheme = lightTheme;
        state.mode = "light";
        state.componentColors = lightComponentColors;
      } else if (state.mode == "light") {
        state.materialTheme = defaultTheme;
        state.mode = "dark";
        state.componentColors = darkComponentColors;
      }
    },
  },
});

export const { changeMode } = themeSlice.actions;

export default themeSlice.reducer;
