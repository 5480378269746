import { IComponentColors } from "../../interfaces/material/componentColors";
import { secondaryColors, detachableColors } from "./palleteColors";

export const darkComponentColors: IComponentColors = {
  primary: {
    primary: "#8CCFF0",
    onPrimary: "#003546",
    primaryContainer: "#004D65",
    onPrimaryContainer: "#BEE9FF",
  },
  secondary: {
    secondary: "#B4CAD6",
    onSecondary: "#1F333D",
    secondaryContainer: "#354A54",
    onSecondaryContainer: "#D0E6F2",
  },
  tertiary: {
    tertiary: "#C7C2EA",
    onTertiary: "#2F2D4C",
    tertiaryContainer: "#464364",
    onTertiaryContainer: "#E4DFFF",
  },
  error: {
    error: "#FFB4AB",
    onError: "#690005",
    errorContainer: "#93000A",
    onErrorContainer: "#FFDAD6",
  },
  surface: {
    surface: "#0F1417",
    onSurface: "#DFE3E7",
    onSurfaceVariant: "#C0C8CD",
    surfaceBright: "#353A3D",
    surfContainerLowest: "#0A0F12",
    surfcontainerLow: "#171C1F",
    surfContainer: "#1B2023",
    surfContainerHigh: "#262B2E",
    surfContainerHighest: "#303538",
    surfaceDim: "#0F1417",
    onSurfaceDetachable: detachableColors[100],
    surfaceFull: "#FFF",
    surfaceContainerHightVariation: "#25252D",
  },
  outline: {
    outline: "#8A9297",
    outlineVariant: "#40484C",
  },
  background: {
    onBackground: "#DFE3E7",
  },
};

const defaultTheme = {
  breakpoints: {
    values: {
      xs: 599,
      sm: 904,
      md: 1239,
      lg: 1440,
      xl: 2556,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: darkComponentColors.primary.primary,
      dark: darkComponentColors.primary.primary,
      light: darkComponentColors.primary.primary,
      contrastText: darkComponentColors.surface.onSurface,
      "50": "#000A3E",
      "100": "#001258",
      "200": "#00228B",
      "300": "#0032C2",
      "400": "#2B4EDE",
      "500": "#4A69F8",
      "600": "#6F88FF",
      "700": "#95A6FF",
      "800": "#B9C3FF",
      "900": "#DEE1FF",
    },
    secondary: {
      main: darkComponentColors.primary.primary,
      dark: darkComponentColors.primary.primary,
      light: darkComponentColors.primary.primary,
      contrastText: darkComponentColors.surface.onSurface,
      ...secondaryColors,
    },
    error: {
      main: darkComponentColors.error.error,
    },
  },
  typography: {
    fontFamily: ["Poppins"],
    allVariants: {
      color: darkComponentColors.surface.onSurface,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: darkComponentColors.outline.outline,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ".MuiPickersDay-today": {
            borderColor: darkComponentColors.primary.primary,
            color: darkComponentColors.primary.primary,
          },
          ".MuiPickersYear-yearButton.Mui-selected": {
            color: darkComponentColors.primary.onPrimary,
          },
          ".MuiPickersMonth-monthButton.Mui-selected": {
            color: darkComponentColors.primary.onPrimary,
          },
          ".MuiButtonBase-root.Mui-selected": {
            color: darkComponentColors.primary.onPrimary,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          button: {
            textTransform: "none",
          },
        },
        indicator: {
          borderRadius: "99px 99px 0 0",
          height: "0.2rem",
        },
      },
    },
  },
};

export default defaultTheme;
