import { IComponentColors } from "../../interfaces/material/componentColors";
import { secondaryColors, detachableColors } from "./palleteColors";

export const lightComponentColors: IComponentColors = {
  primary: {
    primary: "#146683",
    onPrimary: "#FFFFFF",
    primaryContainer: "#BEE9FF",
    onPrimaryContainer: "#001F2A",
  },
  secondary: {
    secondary: "#4D616C",
    onSecondary: "#FFFFFF",
    secondaryContainer: "#D0E6F2",
    onSecondaryContainer: "#081E27",
  },
  tertiary: {
    tertiary: "#5E5B7D",
    onTertiary: "#FFFFFF",
    tertiaryContainer: "#E4DFFF",
    onTertiaryContainer: "#1A1836",
  },
  error: {
    error: "#BA1A1A",
    onError: "#FFFFFF",
    errorContainer: "#FFDAD6",
    onErrorContainer: "#410002",
  },
  surface: {
    surface: "#F6FAFE",
    onSurface: "#171C1F",
    surfaceDim: "#D6DBDE",
    surfaceBright: "#F6FAFE",
    surfContainer: "#EAEEF2",
    surfContainerHigh: "#E4E9EC",
    surfContainerHighest: "#DFE3E7",
    surfcontainerLow: "#F0F4F8",
    surfContainerLowest: "#FFFFFF",
    onSurfaceVariant: "#40484C",
    onSurfaceDetachable: detachableColors[0],
    surfaceFull: "#000",
    surfaceContainerHightVariation: "#EAE7EC",
  },
  outline: {
    outline: "#70787D",
    outlineVariant: "#C0C8CD",
  },
  background: {
    onBackground: "#171C1F",
  },
};

const lightTheme = {
  breakpoints: {
    values: {
      xs: 599,
      sm: 904,
      md: 1239,
      lg: 1440,
      xl: 2556,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: lightComponentColors.primary.primary,
      dark: lightComponentColors.primary.primary,
      contrastText: lightComponentColors.surface.onSurface,
      "50": "#000A3E",
      "100": "#001258",
      "200": "#00228B",
      "300": "#0032C2",
      "400": "#2B4EDE",
      "500": "#4A69F8",
      "600": "#6F88FF",
      "700": "#95A6FF",
      "800": "#B9C3FF",
      "900": "#DEE1FF",
    },
    secondary: {
      main: lightComponentColors.primary.primary,
      dark: lightComponentColors.primary.primary,
      contrastText: lightComponentColors.surface.onSurface,
      ...secondaryColors,
    },
    error: {
      main: lightComponentColors.error.error,
    },
  },
  typography: {
    fontFamily: ["Poppins"],
    allVariants: {
      color: lightComponentColors.surface.onSurface,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: lightComponentColors.outline.outline,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ".MuiPickersDay-today": {
            borderColor: lightComponentColors.primary.primary,
            color: lightComponentColors.primary.primary,
          },
          ".MuiPickersYear-yearButton.Mui-selected": {
            color: lightComponentColors.primary.onPrimary,
          },
          ".MuiPickersMonth-monthButton.Mui-selected": {
            color: lightComponentColors.primary.onPrimary,
          },
          ".MuiButtonBase-root.Mui-selected": {
            color: lightComponentColors.primary.onPrimary,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          button: {
            textTransform: "none",
          },
        },
        indicator: {
          borderRadius: "99px 99px 0 0",
          height: "0.2rem",
        },
      },
    },
  },
};

export default lightTheme;
